import React from "react"
import { graphql } from "gatsby"

import { Link } from "gatsby"

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  // FacebookMessengerShareButton,
  // FacebookMessengerIcon,
  // HatenaShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  PocketShareButton,
  PocketIcon,
  // RedditShareButton,
  TelegramShareButton,
  TelegramIcon,
  // TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  // WorkplaceShareButton
} from "react-share"

import { FaTelegramPlane } from "react-icons/fa"

// import LayoutBlog from "../components/layout-blog"
import Seo from "../components/seo"
import CoverBlog from "../components/cover-blog"
import Newsletter from "../components/newsletter"
import Social from "../components/social"
import Posts from "../components/posts"
import Layout from "../components/layout"
import LayoutBlog from "../components/layout-blog"
import { Helmet } from "react-helmet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  const shareUrl = frontmatter.shortLink
  const encodedUrl = encodeURIComponent(shareUrl)
  const roundShareIcon = true
  // let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Layout blog>
      <Helmet>
        {/* <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696390054962545"
          crossorigin="anonymous"
        ></script> */}
        <link rel="canonical" href={shareUrl} />
      </Helmet>
      <Seo
        title={frontmatter.title}
        description={excerpt}
        imageUrl={frontmatter.featuredImage}
      />
      <CoverBlog
        img={frontmatter.featuredImage}
        offset={frontmatter.offset}
        postTitle={frontmatter.title}
        postAuthor={frontmatter.author}
        postDate={frontmatter.date}
      />
      <section
        id="blog-post"
        style={{
          paddingTop: "2em",
          paddingBottom: "2em",

          // height: "calc(100vw * 9 /16)",
          // maxHeight: "1000px",
        }}
      >
        <div
          style={{
            display: "flex",
            // height: "100%",
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            className="blog-post-container"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div className="blog-post" style={{ maxWidth: "560px" }}>
              {/* <GatsbyImage image={featuredImgFluid} alt={frontmatter.author} /> */}
              <article>
                {/* <h1>{frontmatter.title}</h1>
                <p>{frontmatter.author} - {frontmatter.date}</p> */}
                <div
                  style={{ textAlign: "left" }}
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </article>
              <p
                style={{
                  textAlign: "left",
                }}
              >
                Cet article vous a plu ? Partagez-le
                <br />
                <TelegramShareButton
                  url={shareUrl}
                  title={frontmatter.title}
                  className="Demo__some-network__share-button"
                >
                  <TelegramIcon size={32} round={roundShareIcon} />
                </TelegramShareButton>{" "}
                <WhatsappShareButton
                  url={shareUrl}
                  title={frontmatter.title}
                  separator=":: "
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round={roundShareIcon} />
                </WhatsappShareButton>{" "}
                <FacebookShareButton
                  url={shareUrl}
                  quote={frontmatter.title}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round={roundShareIcon} />
                </FacebookShareButton>{" "}
                <TwitterShareButton
                  url={shareUrl}
                  title={frontmatter.title}
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round={roundShareIcon} />
                </TwitterShareButton>{" "}
                <LinkedinShareButton
                  url={shareUrl}
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon size={32} round={roundShareIcon} />
                </LinkedinShareButton>{" "}
                <EmailShareButton
                  url={shareUrl}
                  subject={frontmatter.title}
                  body="body"
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon size={32} round={roundShareIcon} />
                </EmailShareButton>{" "}
                <PocketShareButton
                  url={shareUrl}
                  title={frontmatter.title}
                  className="Demo__some-network__share-button"
                >
                  <PocketIcon size={32} round={roundShareIcon} />
                </PocketShareButton>{" "}
              </p>
              <p>
                <script
                  async
                  src="https://telegram.org/js/telegram-widget.js?15"
                  data-telegram-discussion={shareUrl}
                  data-comments-limit="50"
                  data-color="343638"
                  data-dark-color="FFFFFF"
                ></script>
                {/* <iframe
                  id="telegram-discussion-sebastiengil-1"
                  src={`https://t.me/sebastiengil?embed=1&discussion=1&page_url=${encodedUrl}&comments_limit=5&color=343638&dark_color=FFFFFF&`}
                  width="100%"
                  height="0"
                  frameborder="0"
                  scrolling="no"
                  style={{
                    overflow: "hidden",
                    border: "none",
                    minWidth: "320px",
                    height: "651px",
                  }}
                ></iframe> */}
                Pour continuer la discussion, ça se passe sur le canal{" "}
                <Link to="https://bit.ly/3oG3qtU">
                  <FaTelegramPlane /> Telegram
                </Link>
                .
              </p>
            </div>
          </div>
          <div
            id="right-column"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              flex: "1",
            }}
          >
            <Posts lastPosts />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "DD/MM/YYYY", locale: "fr")
        slug
        shortLink
        title
        author
        featuredImage
        offset
      }
    }
  }
`
